import * as React from "react";
import PropTypes from "prop-types";

export default class Footer extends React.Component {
  render() {
    return (
      <footer
        className="footer ms-bgColor-neutralLighter ms-u-fadeIn500"
        style={{ textAlign: "center", position: "absolute", bottom: "0", width: "100%" }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="ms-fontWeight-light ms-fontColor-neutralPrimary">© 2022 JL Consulting. Tous droits réservé</p>
        </div>
      </footer>
    );
  }
}
