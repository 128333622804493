import * as React from "react";
import PropTypes from "prop-types";

export default class Header extends React.Component {
  render() {
    const { title, logo, message } = this.props;

    return (
      <section className="header ms-bgColor-neutralLighter ms-u-fadeIn500" style={{ textAlign: "center" , paddingTop : '40px' , paddingBottom : '40px'}}>
        <div style={{ textAlign: "center"}}>
          <img width="90" height="90" src={logo} alt={title} title={title} />
        </div>
        <div style={{ textAlign: "center" }}>
          <h1 className="ms-fontSize-su ms-fontWeight-light ms-fontColor-neutralPrimary">{message}</h1>
        </div>
      </section>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
  logo: PropTypes.string,
  message: PropTypes.string,
};
