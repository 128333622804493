import axios from "../../services/api";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import { Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Progress from "./Progress";

export default function Commentary() {
  const [appSelected, setAppSelected] = useState(0);
  const [objectList, setObjectList] = useState([]);
  const [objectSelected, setObjectSelected] = useState(0);
  const [itemList, setItemList] = useState([]);
  const [itemSelected, setItemSelected] = useState(0);
  const [commentary, setCommentary] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [variantAlert, setVariantAlert] = useState("danger");
  const [alertMessage, setalertMessage] = useState("");
  const [progressStatus, setprogressStatus] = useState(false);
  const [currentEmail, setCurrentEmail] = useState([]);

  console.log(Office.EventType.ItemChanged);

  useEffect(() => {
    Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, itemChanged);
    UpdateTaskPaneUI(Office.context.mailbox.item);
  }, [Office.EventType.ItemChanged]);

  // PSEUDO CODE
  useEffect(() => {
    axios
      .get("/object/active_comments/1?sort=name", {
        headers: {
          "X-BS-Auth-Token": sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        res.data.result.forEach((element) => {
          setObjectList((current) => [...current, { value: element.id, label: element.name }]);
        });
      })
      .catch((res) => {
        let errorMessage = res.response.data.errorMessage;
        console.log(errorMessage);
      });
  }, []);

  useEffect(() => {
    if (objectSelected != 0) {
      setprogressStatus(true);
      getDataBySort("name");
    }
  }, [objectSelected]);

  function itemChanged(eventArgs) {
    // Update UI based on the new current item
    UpdateTaskPaneUI(Office.context.mailbox.item);
  }

  function UpdateTaskPaneUI(item) {
    console.log(item);
    // Assuming that item is always a read item (instead of a compose item).
    if (item != null)
      setCurrentEmail({
        subject: item.subject,
        sender: item.sender.emailAddress,
        createdAt: item.dateTimeCreated,
      });

    setCommentary(
      "From : " + item.sender.emailAddress + " \nAt : " + item.dateTimeCreated + " \nSubject : " + item.subject
    );
  }

  function getDataBySort(sort) {
    setprogressStatus(true);
    setItemList([]);
    axios
      .get(`/v2/data/${objectSelected}?sort=${sort}&sortDirection=ASC&skipFields=1`, {
        headers: {
          "X-BS-Auth-Token": sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.status == 42) {
          if (res.data.msg.includes("hashtag") || res.data.msg.includes("iframe_product")) {
          } else {
            if (sort === "name") {
              getDataBySort("firstname");
            } else if (sort === "firstname") {
              getDataBySort("title");
            } else if (sort === "firstname" || sort === "title") {
              getDataBySort("name");
            } else {
              console.log(res.data.msg);
            }
          }
        } else {
          setItemList([]);
          res.data.result.forEach((element) => {
            let name = element.name || element.title || `${element.firstname} ${element.lastname}`;
            setItemList((current) => [...current, { value: element.id, label: name }]);
          });
          setprogressStatus(false);
        }
      })
      .catch((res) => {
        let errorMessage = res.response.data.errorMessage;

        console.log(errorMessage);
      });
  }

  function handleSelectChange(event) {
    setObjectSelected(event.value);
    axios
      .get(`/object/${event.value}`, {
        headers: {
          "X-BS-Auth-Token": sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setAppSelected(res.data.result.id_application);
      });
    setItemSelected(0);
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${day}-${month}-${year}`;
    setCommentary(
      "From : " + currentEmail.sender + " \nAt : " + currentEmail.createdAt + " \nSubject : " + currentEmail.subject
    );
  }

  function handleItemChange(event) {
    setItemSelected(event.value);
    setCommentary(
      "From : " + currentEmail.sender + " \nAt : " + currentEmail.createdAt + " \nSubject : " + currentEmail.subject
    );
  }

  function handleChange(event) {
    setCommentary((current) => [...current, event.target.value]);
    setCommentary(event.target.value);
  }

  function handleSubmit(event) {
    // A changer voir en fonction de l'API
    let user = jwt_decode(sessionStorage.getItem("token"));

    var params = {
      id_app: appSelected,
      id_user: Math.ceil(user.id / 146784),
      id_page: objectSelected,
      id_row: itemSelected,
      comment: commentary,
    };

    var bodyFormData = new FormData();

    let com = commentary.replace(/\n/g, "<br />");

    bodyFormData.append("id_app", appSelected);
    bodyFormData.append("id_user", Math.ceil(user.id / 146784));
    bodyFormData.append("id_page", objectSelected);
    bodyFormData.append("id_row", itemSelected);
    bodyFormData.append("comment", com);

    axios
      .post("/comment", bodyFormData, {
        headers: {
          "X-BS-Auth-Token": sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (res.data.result.id_user) {
          setShowAlert(true);
          setVariantAlert("success");
          setalertMessage("Your commentary has been sent. ");
        } else {
          setShowAlert(true);
          setVariantAlert("danger");
          setalertMessage("Error occured");
        }
      })
      .catch((res) => {
        let errorMessage = res.response.data.errorMessage;
        console.log(errorMessage);
      });

    event.preventDefault();
  }

  return (
    <Container>
      <Row>
        <Col lg="12" xs="12" sm="12">
          <div className="m-2">
            <label>Select a type</label>
            <Select
              options={objectList}
              onChange={(e) => {
                handleSelectChange(e);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12" xs="12" sm="12">
          {itemList.length > 0 ? (
            <div className="m-2">
              <label>Select an object</label>
              <Select
                options={itemList}
                onChange={(e) => {
                  handleItemChange(e);
                }}
              />
            </div>
          ) : progressStatus ? (
            <Progress message="Wait for data." />
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <Col lg="12" xs="12" sm="12">
          {itemSelected > 0 ? (
            <div>
              <label>Comment </label>
            </div>
          ) : (
            ""
          )}
        </Col>
        <Col lg="12" xs="12" sm="12">
          {itemSelected != 0 ? (
            <div className="m-2">
              <textarea
                style={{ width: "100%", height: "80%", padding: 5 }}
                name="textValue"
                placeholder="Comment"
                value={commentary}
                onChange={(e) => handleChange(e)}
              />
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <Col lg="12" xs="12" sm="12" style={{ display: "flex", justifyContent: "center", alignItem: "center" }}>
          {itemSelected != 0 ? (
            <Button
              variant="primary"
              type="submit"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Send
            </Button>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        {showAlert ? (
          variantAlert == "success" ? (
            <div>
              <Alert key={variantAlert} variant={variantAlert}>
                {alertMessage}
              </Alert>
              <p className="text-secondary">You can set another comment if you want.</p>
            </div>
          ) : (
            <div>
              <Alert key={variantAlert} variant={variantAlert}>
                {alertMessage}
              </Alert>
            </div>
          )
        ) : (
          <div></div>
        )}
      </Row>
    </Container>
  );
}
