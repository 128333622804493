import * as React from "react";
import { DefaultButton } from "@fluentui/react";
import Header from "./Header";
import Progress from "./Progress";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Alert } from "react-bootstrap";
import api from "../../services/api";
import Commentary from "./Commentary";
import Footer from "./Footer";

/* global require */

export default class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      login: "",
      password: "",
      isAuth: false,
      alertMessage: "",
      alertShow: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const storage = sessionStorage.getItem("token") || null;

    if (storage !== "" && storage !== null) {
      this.setState({ isAuth: true });
    }
  }

  async handleChange(event) {
    var name = event.target.name;
    this.setState({ alertShow: false });
    this.setState({ [name]: event.target.value });
  }

  async handleSubmit(event) {
    const user = {
      login: this.state.login,
      pwd: this.state.password,
    };

     api.post('/token', user)
      .then((res) => {
        sessionStorage.setItem("token", res.data.result.token);
        api.defaults.headers.common['X-BS-Auth-Token'] = res.data.result.token;
        this.setState({ isAuth: true });
      })
      .catch((res) => {
        let errorMessage = res.response.data.errorMessage;
        if (errorMessage == "Not Authorized") {
          this.setState({ alertMessage: "Identifiant incorrect" });
          this.setState({ alertShow: true });
        }
      });

    event.preventDefault();
  }

  render() {
    const { title, isOfficeInitialized } = this.props;
    const storage = sessionStorage.getItem("token") || null;

    if (!isOfficeInitialized) {
      return (
        <Progress title={title} logo={require("./../../../assets/logo-filled.png")} message="Veuillez patienter." />
      );
    }

    if (this.state.isAuth == false) {
      return (
        <div className="ms-welcome">
          <div className="header">
          <Header logo={require("./../../../assets/CRMSIDE.png")} title={this.props.title} message="Welcome" />
          </div>
          <Container>
            <Row>
              <Form onSubmit={this.handleSubmit} className="crmside-form">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Login</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Entrer un identifiant"
                    name="login"
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    required
                    placeholder="Password"
                    name="password"
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Sign in
                </Button>
              </Form>
            </Row>
            <Row>
              <Col style={{ display: "flex", justifyContent: "center", alignItem: "center" }}>
                {this.state.alertShow ? (
                  <div>
                    <div>
                      <Alert key={"danger"} variant={"danger"}>
                        {this.state.alertMessage}
                      </Alert>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (this.state.isAuth == true) {
      return (
        <div className="ms-welcome">
          <Header logo={require("./../../../assets/CRMSIDE.png")} title={this.props.title} message="Welcome" />
          <Commentary />
          <Footer />
        </div>
      );
    }
  }
}
